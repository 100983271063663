import {
  formatDateFrom,
  formatId,
  getFullName,
  numWord,
  sumFootage,
} from "../../../utils";

/** @type {Record<import("../../../api").FlatTokenizationStatus, string>} */
export const tokenizedStatusLabelMap = {
  NOT_TOKENIZED: "Не токенизирована",
  WAIT_TOKENIZATION: "Ожидает токенизации",
  TOKENIZATION: "Идет токенизация",
  TOKENIZED: "Токенизирована",
};

export const tokenizedReleaseStatusLabelMap = {
  NOT_TOKENIZED: "Не токенизирован",
  WAIT_TOKENIZATION: "Ожидает токенизации",
  TOKENIZATION: "Идет токенизация",
  TOKENIZED: "Токенизирован",
  CANCELLED: "Выпуск отменен",
};

/** @type {Record<import("../../../api").FlatBookingStatus, string>} */
export const bookingStatusLabelMap = {
  NOT_BOOKED: "Доступна для бронирования",
  BOOKED: "Забронирована",
  RELEASED: "Продана",
  REMOVED_FROM_POOL: "Удалена из пула",
};

/** @type {Record<import("../../../api").OrderStatus, string>} */
export const orderStatusLabelMap = {
  INIT_PROSPECTUS_CREATED: "Ожидается отправка в ЦФА ХАБ",
  INIT_PROSPECTUS_SENT: "Отправлено в ЦФА ХАБ",
  WAITING_FOR_PAYMENT: "Деньги зачисляются",
  WAITING_FOR_CONFIRMATION: "Проверка оплаты",
  PAYMENT_CONFIRMATION_SENT: "Подтверждение оплаты",
  PAYMENT_CONFIRMED: "Оплата подтверждена",
  CANCELED_BY_OIS: "Отклонено ЦФА ХАБ",
  WAITING_ISSUE: "Подтверждено, ожидается выпуск",
  CLOSED: "Завершено",
  CANCELED_BY_INVESTOR: "Отклонено",
  CANCEL_CONFIRMED_BY_OIS: "Отклонено ЦФА ХАБ",
  CANCELED: "Отменено",
  EXPIRED: "Истек срок",
  FAILED_HANDLE_EVENT_ORDER: "Ошибка создания заявки",
  BUY_ORDER_CREATED: "Операция по покупке ЦФА",
  BUY_ORDER_CLOSED: "Завершение операции по покупке ЦФА",
  SELL_ORDER_CREATED: "Операция по продаже ЦФА",
  SELL_ORDER_CLOSED: "Завершение операции по продаже ЦФА",
  BURNING_CREATED: "Процедура погашения ЦФА",
  BURNING_CLOSED: "Подтверждение погашения ЦФА завершено",
  BURNING_WAITING_CONFIRMATION: "Ожидает подтверждения погашения ЦФА",
  BURNING_WAITING_PAYMENT: "Подтверждение по погашению ЦФА отклонено",
  BURNING_CONFIRMATION_SENT: "Подтверждение на погашение ЦФА отправлено",
  BURNING_CONFIRMED: "Процедура подтверждения погашения ЦФА",
  BURNING_REJECT_SENT: "Отклонение по погашению ЦФА отправлено",
  TRANSFER_CREATED: "Операция по передаче ЦФА",
  TRANSFER_CLOSED: "Завершение операции по передаче ЦФА",
  EXCHANGE_CREATED: "Операция по обмену ЦФА",
  EXCHANGE_CLOSED: "Завершение операции по обмену ЦФА",
  CREATED: "Создана",
  WAITING_CONFIRMATION: "Ожидает подтверждения",
  CONFIRMATION_SENT: "Подтверждение отправлено",
  REJECT_SENT: "Отказ отправлен",
  WAITING_PAYMENT: "Ожидает оплату",
  CONFIRMED: "Подтверждено",
  CLOSED_BURNING: "Погашено",
};

export function formatFlatsInfo(flatsData, building) {
  if (!flatsData || flatsData.length === 0) return null;

  return {
    "Номер Квартиры": flatsData.map((flat) => ({
      name: `Кв.  ${flat.number}`,
      link: `../flats/${flat.id}`,
    })),
    "ID Квартиры": flatsData.map((flat) => flat.id),
    "Статус токенизации": flatsData.map((flat) => flat.tokenizationStatus),
    "Статус бронирования": flatsData.map((flat) => flat.bookingStatus),
    ЖК: building
      ? {
          name: building.name,
          link: `../buildings/${building.id}`,
        }
      : flatsData.map((flat) => ({
          name: flat.building.name,
          link: `../buildings/${flat.building.id}`,
        })),
    "ID ЖК": building ? building.id : flatsData.map((flat) => flat.building.id),

    Метраж: flatsData.map((flat) => `${flat.footage} м²`),
    Адрес: flatsData.map(
      (flat) =>
        `${flat.blockNumber} корпус, ${flat.entranceNumber} подъезд, ${flat.floor} этаж`,
    ),
  };
}

export function formatReleasesInfo(releasesData) {
  if (!releasesData || releasesData.length === 0) return null;
  return {
    "Наименование выпуска": releasesData?.map((release) => ({
      name: release?.name || "Без наименования",
      link: `../releases/${release.id}`,
    })),
    "ID выпуска": releasesData?.map((it) => formatId(it.id)),
    Токенизация: releasesData?.map((it) => it.status),

    "Наименование пула": releasesData?.map(({ pool }) => ({
      name: pool?.name,
      link: `../pools/${pool.id}`,
    })),

    "ID пула": releasesData?.map(({ pool }) => formatId(pool.id)),
    Эмитент: releasesData?.map(({ emitter }) => ({
      name: emitter?.name,
      link: `../emitters/${emitter.id}`,
    })),
    "ID эмитента": releasesData?.map((it) => formatId(it.emitter.userId)),

    ЖК: releasesData?.map((it) => ({
      name: it.building?.name,
      link: `../buildings/${it.building?.id}`,
    })),

    "ID ЖК": releasesData?.map((it) => formatId(it.building.id)),

    "Количество квартир": releasesData?.map(
      (it) =>
        `${it.flatsList.length} ${numWord(it.flatsList.length, [
          "квартира",
          "квартиры",
          "квартир",
        ])}`,
    ),
    "Общий метраж": releasesData?.map((it) => `${it.totalFootage} м²`),

    "Стоимость 100 ЦФА": releasesData?.map((it) => `${it.price} ₽`),
  };
}

export function formatPoolsInfo(pools) {
  if (!pools) return null;

  return {
    "Наименование пула": pools?.map((pool) => ({
      name: pool?.name,
      link: `../pools/${pool.id}`,
    })),

    "ID пула": pools?.map((pool) => formatId(pool.id)),

    ЖК: pools?.map((pool) => ({
      name: pool?.building?.name,
      link: `../buildings/${pool?.building?.id}`,
    })),
    "ID ЖК": pools?.map((pool) => formatId(pool?.building?.id)),

    "Количество квартир в пуле": pools?.map(
      (pool) =>
        `${pool.flatsList.length} ${numWord(pool.flatsList.length, [
          "квартира",
          "квартиры",
          "квартир",
        ])}`,
    ),
    "Общий метраж": pools?.map((pool) => sumFootage(pool?.flatsList)),

    "Участие в выпусках": pools?.map(
      (pool) =>
        `${pool.releases.length} ${numWord(pool.releases.length, [
          "выпуск",
          "выпуска",
          "выпусков",
        ])}`,
    ),
  };
}

export function formatOrdersInfo(ordersData) {
  if (!ordersData || ordersData.length === 0) return null;
  return {
    "ID заявки": ordersData?.map((it) => ({
      name: formatId(it.id),
      link: `../orders/${it.id}`,
    })),
    "ID заявки в ОИС": ordersData?.map((it) => formatId(it.idOis)),
    "Статус сделки": ordersData.map((it) => it.status),
    "Кол-во": ordersData?.map((el) => el.amount / 100),
    "Цена 100 ЦФА при погашении": ordersData?.map(
      (el) => `${el.priceForOne * 100} ₽`,
    ),
    Дата: ordersData?.map((it) => formatDateFrom(it.createdAt)),
    Выпуск: ordersData?.map(({ release }) => ({
      name: release?.name,
      link: `../users/${release.id}`,
    })),

    "ID выпуска": ordersData?.map((it) => formatId(it.release?.id)),
    "Пользователь эмитента": ordersData?.map(({ issuer }) => ({
      name: getFullName(issuer),
      link: `../users/${issuer.id}`,
    })),
    "ID пользователя эмитента": ordersData?.map((it) =>
      formatId(it.issuer?.id),
    ),

    Покупатель: ordersData?.map(({ owner }) => ({
      name: getFullName(owner),
      link: `../users/${owner.id}`,
    })),
    "ID покупателя": ordersData?.map((it) => formatId(it.owner?.id)),

    // Время: ordersData?.map((el) => el.time),
  };
}
