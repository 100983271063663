import classNames from "classnames";
import { useState } from "react";

const Textarea = ({
  size = "default",
  placeholder = 0,
  withLabel = false,
  withNotification = false,
  value = "",
  setValue,
  addClass,
  labelSize = "default",
  ref,
  withError = false,
  disabled = false,
  ...arg
}) => {
  // const [textAreaValue, setValue] = useState(value);
  const inputClass = classNames({
    "input input_textarea": true,
    [`input_${size}`]: true,
    [`${addClass}`]: addClass,
  });

  const labelClass = classNames({
    "input-wrapper__label": true,
    "body-smaller": labelSize === "small",
  });

  return (
    <label className="input-wrapper">
      {withLabel && <span className={labelClass}>{withLabel}</span>}

      <textarea
        className={inputClass}
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        onChange={(evt) => setValue(evt.target.value)}
        {...arg}
        ref={ref}
      ></textarea>

      {withError && <span className="input-error base-12">{withError}</span>}
    </label>
  );
};

export default Textarea;
