export const answersOnQuestions = [
  // 1
  {
    question: "Что такое «Цифровой квадрат»?",
    answersList: [
      {
        answer:
          "«Цифровой квадрат» - это платформа, на которой представлены выпуски ЦФА строящихся новостроек. У нас ЦФА связаны с квадратными метрами в недвижимости. 100 ЦФА = стоимости 1 квадратного метра. ЦФА – доступный всем инструмент инвестирования: их можно приобрести на сумму от 50 000 Р, а в дату погашения ЦФА – получить фиксированный доход.",
      },
    ],
  },
  // 2
  {
    question: "Что такое «ЦФА ХАБ»?",
    answersList: [
      {
        answer:
          "«ЦФА ХАБ» - платформа-партнёр «Цифрового квадрата». Это оператор информационной системы (ОИС), входящий в реестр Банка России. В «ЦФА ХАБ» осуществляются выпуски ЦФА, которые потом отображаются в «Цифровом квадрате».",
      },
    ],
  },
  // 3
  {
    question: "В чём отличие «Цифрового квадрата» от «ЦФА ХАБ»?",
    answersList: [
      {
        answer:
          "«ЦФА ХАБ» и «Цифровой квадрат» связаны интеграцией, по которой ЦФА новостроек отображаются в «Цифровом квадрате». «Цифровой квадрат» – это удобная «витрина», на которой вы можете проводить все нужные операции с вашими ЦФА, не используя интерфейс «ЦФА ХАБ». ",
      },
    ],
  },
  // 4
  {
    question: "Что такое ЦФА и как он относится к квадратному метру?",
    answersList: [
      {
        answer:
          "ЦФА (цифровые финансовые активы) -  это инструмент для инвестиций на базе технологий блокчейна и смарт-контрактов. Упрощённо - это цифровая оболочка, в которую можно «обернуть» разные активы, а в случае «Цифрового квадрата» – это метры строящейся недвижимости. Отметим, что приобретается именно доходность от квадратного метра.",
      },
    ],
  },
  // 5
  {
    question: "Кто такой эмитент и инвестор?",
    answersList: [
      {
        answer:
          "Эмитент - юридическое лицо, которое выпускает ЦФА и размещает решение о выпуске в «ЦФА ХАБ». Эмитент  – это организация неразрывно связанная с застройщиком.",
      },
      {
        answer:
          "Инвестор - это пользователь (юридическое или физическое лицо), который приобретает ЦФА.",
      },
    ],
  },
  // 6
  {
    question: "Кто может приобретать ЦФА на «Цифровом квадрате»?",
    answersList: [
      {
        answer:
          "Наша цель – дать возможность инвестировать в строящиеся новостройки каждому, в первую очередь физическим лицам, неквалифицированным инвесторам. Сейчас проходит тестирование платформы, поэтому выпуски ЦФА доступны только для юридических лиц и ИП.",
      },
    ],
  },
  // 7
  {
    question: "Какая структура выпусков ЦФА на «Цифровом квадрате»?",
    answersList: [
      {
        answer:
          "Выпуски ЦФА от разных застройщиков на «Цифровом квадрате» имеют единую структуру. Застройщик заключает с эмитентом инвестиционный договор. В инвестиционном договоре указывается стоимость квадратного метра, за которую будут продаваться ЦФА и стоимость квадратного метра после сдачи дома в дату погашения (доходность). Также указываются информация по квартирам, которые будут участвовать в выпуске. По условиям квартиры выводятся с продаж, до момента погашения ЦФА. Привлеченные деньги с выпуска ЦФА эмитент перечисляет застройщику для финансирования строительства.",
      },
    ],
  },
  // 8
  {
    question: "Как определяется доходность ЦФА?",
    answersList: [
      {
        answer:
          "Доходность квадратного метра и срок выплаты фиксируется заранее. В решение о выпуске ЦФА каждого жилого комплекса устанавливается своя доходность, определяет её застройщик в рамках инвестиционного договора. Это означает, что при приобретении ЦФА уже известна сумма, которая будет получена при погашении.",
      },
    ],
  },
  // 9
  {
    question: "Когда будет получена доходность?",
    answersList: [
      {
        answer:
          "В решении о выпуске ЦФА указана дата погашения, она же отображена в карточке выпуска на «Цифровом квадрате».",
      },
    ],
  },
  // 10
  {
    question: "Как приобретаются ЦФА?",
    answersList: [
      {
        answer:
          "Выбираете активный выпуск ЦФА, в котором принимаются заявки на покупку (обычно сбор заявок длится 1-2 недели) → указываете сумму, производите оплату → получаете подтверждение от эмитента и «ЦФА ХАБ» → если ваша заявка принята, ожидаете выпуска. Если выпуск состоится, вам будут начислены ЦФА, если нет – деньги вернет эмитент.",
      },
    ],
  },
  // 11
  {
    question: "Как приобрести ЦФА с помощью «Цифрового квадрата»?",
    answersList: [
      {
        answer:
          "Нужно пройти регистрацию в «Цифровом квадрате» и обязательную регистрацию и идентификацию в «ЦФА ХАБ», после чего связать ваши аккаунты, выполнив авторизацию.",
      },
      {
        answer:
          "В карточке выпуска ЖК нажать «Купить» и далее следовать по указаниям. В разделе «Мой метр» → «Сделки» → «В процессе», вы сможете отслеживать статусы по текущим сделкам. Когда выпуск состоится и ЦФА начислятся, они появятся в разделе «Мой метр» → «Мои ЦФА». Более подробно процесс описан в «Руководстве пользователя».",
      },
    ],
  },
  // 12
  {
    question:
      "Зачем подтверждать заявку на покупку ЦФА с помощью электронной подписи?",
    answersList: [
      {
        answer:
          "На данный момент купить ЦФА могут на «Цифровом квадрате» только юридические лица и ИП, поэтому требуется подписание заявки с помощью ЭЦП. Для физических лиц будет доступна простая электронная подпись.",
      },
    ],
  },
  // 13
  {
    question: "Что такое пул, что такое выпуск и как они связаны?",
    answersList: [
      {
        answer:
          "Сначала застройщик выделяет список квартир в ЖК для токенизации, которые образуют пул. Затем из пула выбирается несколько квартир, которые участвуют в выпуске. Приобретаются ЦФА в конкретном выпуске определенного пула.",
      },
      {
        answer:
          "В одном ЖК может быть несколько пулов, причем одна и та же квартира может быть в нескольких пулах (её часть). В одном пуле может быть несколько выпусков, они размещаются последовательно: завершился один, начался следующий.",
      },
    ],
  },
  // 14
  {
    question: "Зачем нужен пул?",
    answersList: [
      {
        answer:
          "На «Цифровом квадрате» предусмотрена акция по обмену ЦФА на квартиру из пула. Данная акция доступна не во всех пулах.",
      },
    ],
  },
  // 15
  {
    question: "Какие характеристики имеет пул?",
    answersList: [
      {
        answer: "Не все квартиры из пула могут быть токенизированы,",
      },
      {
        answer:
          "квартиры могут удаляться из пула и продаваться вне, если еще не токенизированы,",
      },
      {
        answer:
          "новые квартиры в пул не могут добавляться, если застройщик выделил новые квартиры – создается новый пул,",
      },
      {
        answer: "одном пуле может быть много выпусков ЦФА,",
      },
      {
        answer:
          "пул имеет единую стоимость 100 ЦФА (стоимость одного квадратного метра)  при погашении и процент годовой доходности. Если в решение о выпуске ЦФА предусмотрена другая доходность за 100 ЦФА, то создается новый пул.",
      },
    ],
  },

  // 16
  {
    question: "Как можно обменять ЦФА на квартиру?",
    answersList: [
      {
        answer:
          "Количество ЦФА во владении должно хватать на квартиру. Например, в пуле есть квартира 50 квадратных метров, в выпусках ЦФА этого пула у вас на балансе должно быть 5000 ЦФА.",
      },
      {
        answer:
          "В случае, если накопленных ЦФА недостаточно, то можно докупить квадратные метры напрямую у застройщика по рыночной стоимости на момент покупки. Для того, чтобы воспользоваться этой акцией, необходимо написать на почту support@1meter.ru.",
      },
    ],
  },

  // 17
  {
    question: "Первичная покупка и вторичный рынок - в чём отличия?",
    answersList: [
      {
        answer: "Первичная покупка - это покупка ЦФА у эмитента.",
      },
      {
        answer:
          "Вторичный рынок - это покупка, продажа, передача и обмен уже выпущенных ЦФА между инвесторами.",
      },
      {
        answer:
          "На «Цифровом квадрате» на данный момент работает первичная покупка. Вторичный рынок станет доступен в ближайшее время.",
      },
    ],
  },
  // 18
  {
    question: "Насколько можно доверять эмитенту и честности процесса?",
    answersList: [
      {
        answer:
          "Если будет нарушена честность процесса приобретения ЦФА со стороны эмитента, то требования, связанные с обладанием ЦФА, инвесторы смогут защитить в суде, так как обладание ЦФА описано в законодательстве РФ и защищено законом.",
      },
    ],
  },
];
