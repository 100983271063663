import { useMemo } from "react";
import Button from "../Button/Button";
import Icon from "./Icon";
import "./ReleaseLink.scss";
import CloseButton from "../CloseButton/CloseButton";
import Modal from "react-modal";
import useModal from "../../../hooks/useModal";
import { formatDateFrom, formatId } from "../../../utils";
import { getOrders } from "../../../api";
import { useQuery } from "react-query";
const ReleaseLink = ({
  release,
  noIcon = false,
  ellipsis = false,
  children,
  needToConfirm = false,
}) => {
  const { modalIsOpen, openModal, closeModal } = useModal();

  const { data: ordersInRelease } = useQuery({
    queryKey: ["orders", release.id],
    enabled: !!needToConfirm,
    queryFn: () =>
      getOrders({
        releaseId: release.idOis,
        orderType: "BURNING",
      }),
  });

  const orderNeedToConfirm = useMemo(() => {
    if (ordersInRelease) {
      return ordersInRelease.find(
        (order) =>
          order.status === "WAITING_CONFIRMATION" ||
          order.status === "WAITING_PAYMENT" ||
          order.status === "REJECT_SENT"
      );
    }
    return null;
  }, [ordersInRelease]);

  const disabledConfirmButton = {
    WAITING_PAYMENT: true,
    REJECT_SENT: true,
  };

  if (!release) return null;

  const { docReleaseLink, riskNotificationLink, detailedConditionsLink } =
    release;

  return (
    <>
      <div className="inline-flex gap-10 items-center">
        <Button addClass="release-link" isClean onClick={openModal}>
          <span
            className="link link_blue"
            style={
              ellipsis
                ? {
                    display: "inline-block",
                    width: "90px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }
                : {}
            }
          >
            {children || release.name || formatId(release.id)}
          </span>
          {!noIcon && <Icon />}
        </Button>
        {needToConfirm && orderNeedToConfirm && (
          <Button
            className="release-link__confirm"
            buttonType="primary"
            size="xs"
            onClick={needToConfirm}
            disabled={
              disabledConfirmButton[ordersInRelease[0]?.status] ?? false
            }
          >
            Подтвердить
          </Button>
        )}
      </div>
      {release && (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          className="modal-wrapper"
          overlayClassName="modal-overlay"
        >
          <div className="modal">
            <CloseButton addClass="modal__close-btn" onClick={closeModal} />
            <p className="h8" style={{ marginBottom: 10 }}>
              {release.name || formatId(release.id)}
            </p>

            <dl className="dl">
              {release?.plannedStartDate && (
                <div>
                  <dt>Дата начала сбора заявок</dt>
                  <dd>
                    <span>
                      {formatDateFrom(release?.plannedStartDate, true)}
                    </span>
                  </dd>
                </div>
              )}
              {release?.plannedEndDate && (
                <div>
                  <dt>Дата окончания сбора заявок</dt>
                  <dd>
                    <span>{formatDateFrom(release?.plannedEndDate, true)}</span>
                  </dd>
                </div>
              )}

              {release?.burningDate && (
                <div>
                  <dt>Дата погашения ЦФА</dt>

                  <dd>
                    <span>{formatDateFrom(release?.burningDate, true)}</span>
                  </dd>
                </div>
              )}

              {docReleaseLink && (
                <div>
                  <dt>Решение о выпуске</dt>
                  <dd>
                    <a className="link link_blue" href={docReleaseLink}>
                      Ознакомиться
                    </a>
                  </dd>
                </div>
              )}

              {riskNotificationLink && (
                <div>
                  <dt>Уведомление о рисках</dt>
                  <dd>
                    <a className="link link_blue" href={riskNotificationLink}>
                      Ознакомиться
                    </a>
                  </dd>
                </div>
              )}
              {detailedConditionsLink && (
                <div>
                  <dt>Инвестиционный договор между Эмитентом и Застройщиком</dt>
                  <dd>
                    <a className="link link_blue" href={detailedConditionsLink}>
                      Ознакомиться
                    </a>
                  </dd>
                </div>
              )}
            </dl>
          </div>
        </Modal>
      )}
    </>
  );
};

export default ReleaseLink;
