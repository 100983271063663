import classNames from "classnames";
import Button from "../Button/Button";
import Input from "../Input/Input";
import CloseButton from "../CloseButton/CloseButton";
import { useState, useMemo, useEffect, useCallback } from "react";
import "./index.scss";
import ReleaseLink from "../ReleaseLink/ReleaseLink";
import { numWord, formatTokensForDisplay } from "../../../utils";
import LimitInvestor from "./LimitInvestor";
import SignForm from "./SignForm";
import PaymentScreen from "./PaymentScreen";
import NeedToEnterScreen from "./NeedToEnterScreen";
import NeedToVerifyScreen from "./NeedToVerifyScreen";
import NoTokensScreen from "./NoTokensScreen";
import usePathId from "../../../hooks/usePathId";
import usePurchaseData from "../../../hooks/usePurchaseData";
import useIsLoggedIn from "../../../hooks/useIsLoggedIn";
import useIsVerified from "../../../hooks/useIsVerified";
import { useMutation } from "react-query";
import { initOrderMutation } from "../../../api";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import { useActiveOrdersForUser } from "../../../hooks/fetchers/orders";
import useOisHealth from "../../../hooks/fetchers/useOisHealth";
import NoApiScreen from "./NoApiScreen";
import ProspectusErrorScreen from "./ProspecusErrorScreen";
import SuccessScreen from "./SuccessScreen";
import useEscButton from "../../../hooks/useEscButton";
import { config } from "../../../config";
import useUserId from "../../../hooks/useUserId";
import useMobile from "../../../hooks/useMobile";

const PurchaseCard = ({
  releaseForDisplay,
  isHorizontal,
  isMySells,
  addClass,
  isOpened,
  setIsOpened,
  availableTokens,
  buttonText = "Далее",
}) => {
  const isMobile = useMobile();
  const userId = useUserId();
  useEscButton(() => setIsOpened(false));
  const id = usePathId();
  const isLoggedIn = useIsLoggedIn();
  const isVerified = useIsVerified();

  const [step, setStep] = useState(
    availableTokens > 0 ? "calculator" : "no-tokens"
  );

  // Шаг покупки
  // "calculator" -> "sign-form" -> "payment-screen" -> "loading-screen" -> "success-screen" | "need-to-enter" | "need-to-verify" | "no-tokens" | "no-api"

  const setOrderStatus = useCallback(
    (status) => {
      if (availableTokens <= 0) setStep("no-tokens");
      if (status === "INIT_PROSPECTUS_CREATED") setStep("sign-form");
      if (status === "INIT_PROSPECTUS_SENT") setStep("loading-screen");
      if (status === "WAITING_FOR_PAYMENT") setStep("payment-screen");
      if (
        [
          "WAITING_FOR_CONFIRMATION",
          "PAYMENT_CONFIRMED",
          "WAITING_ISSUE",
        ].includes(status)
      )
        setStep("success-screen");
    },
    [availableTokens]
  );

  const {
    data: ordersData,
    isLoading: isActiveOrdersLoading,
    refetch: refetchActiveOrders,
  } = useActiveOrdersForUser(
    {
      pool_id: id,
    },

    // onSuccess
    (data) => {
      if (data && data[0] && userId) {
        const userOrders = data.sort(
          (a, b) => new Date(a.updatedAt) - new Date(b.updatedAt)
        );
        const lastActiveOrder = userOrders[userOrders.length - 1];
        setOrder({
          internalId: lastActiveOrder.id,
          prospectusId: lastActiveOrder.initProspectusId,
        });
        setOrderStatus(lastActiveOrder.status);
        return;
      }
      setStep(availableTokens > 0 ? "calculator" : "no-tokens");
    }
  );

  const activeOrders = useMemo(
    () =>
      ordersData &&
      ordersData.length > 0 &&
      ordersData.sort((a, b) => new Date(a.updatedAt) - new Date(b.updatedAt)),
    [ordersData]
  );

  const { data: oisHealth } = useOisHealth({
    onSuccess: () => {
      if (!oisHealth) setStep("no-api");
    },
  });

  // const releaseForDisplay = getOldestRelease(activeReleases);

  const MIN_PRICE = config.purchaseMinPrice;
  const MAX_PRICE = config.purchaseMaxPrice;

  const { savePurchaseData, getPurchaseData, clearPurchaseData } =
    usePurchaseData();
  const data = getPurchaseData();
  const [price, setPrice] = useState(data?.price);
  const [amount, setAmount] = useState(data?.amount);
  const [showLimit, setShowLimit] = useState(false);

  const [order, setOrder] = useState({
    internalId: "",
    prospectusId: "",
  });

  const totalAmount = useMemo(
    // --- Логика для нескольких релизов ----

    // () =>
    //   activeReleases &&
    //   activeReleases.reduce(
    //     (sumTokens, releaseForDisplay) =>
    //       sumTokens + formatTokensForDisplay(releaseForDisplay.availableTokens),
    //     0
    //   ),

    () => formatTokensForDisplay(releaseForDisplay.availableTokens),
    [releaseForDisplay]
  );

  const priceOneCFA = useMemo(
    () => releaseForDisplay.price / 100,
    [releaseForDisplay.price]
  );

  const onAmountChange = useCallback(
    (value) => {
      if (isNaN(value)) {
        setAmount(0);
        setPrice(0);
        return;
      }

      if (releaseForDisplay) {
        setAmount(+value);
        setPrice((+value * priceOneCFA).toFixed(2).replace(/\.00$/, ""));
      }
    },
    [releaseForDisplay, priceOneCFA]
  );

  const onPriceChange = useCallback(
    (value) => {
      if (isNaN(value)) {
        setAmount(0);
        setPrice(0);
        return;
      }
      setPrice(+value);
      setAmount(
        Math.floor((+value * 100) / releaseForDisplay.price).toFixed()
        // так нужно, чтобы округляло вниз корректно после toFixed
      );
    },
    [releaseForDisplay]
  );

  // Пункт 1 - инициализация order
  const initOrder = useMutation({
    mutationFn: initOrderMutation,
    onSuccess: (data) => {
      const convertValues = Object.values(data);
      const lastActiveOrder = convertValues[convertValues.length - 1];
      const getValues = Object.values(lastActiveOrder); // ToDo - переделать под несколько релизов

      // +++

      setOrder({
        internalId: getValues[0].id,
        prospectusId: getValues[0].initProspectusId,
      });

      setOrderStatus(getValues[0].status);
      clearPurchaseData();
    },
  });

  const refetchOrder = useCallback(() => {
    refetchActiveOrders().then(() => {
      if (
        activeOrders &&
        activeOrders[0] &&
        activeOrders[activeOrders.length - 1].status === "WAITING_FOR_PAYMENT"
      ) {
        setStep("payment-screen");
      }
    });
  }, [activeOrders, refetchActiveOrders]);

  const isLoading = initOrder.isLoading;

  useEffect(() => {
    if (amount > totalAmount) {
      onAmountChange(totalAmount);
    }
  }, [amount, price, onAmountChange, totalAmount, onPriceChange]);

  useEffect(() => {
    // Рефакторинг
    !oisHealth
      ? setStep("no-api")
      : availableTokens === 0
        ? setStep("no-tokens")
        : activeOrders && activeOrders[0]
          ? setOrderStatus(activeOrders[activeOrders.length - 1].status)
          : setStep("calculator");
  }, [
    availableTokens,
    setOrderStatus,
    activeOrders,
    isOpened,
    isLoggedIn,
    isVerified,
    oisHealth,
  ]);

  useEffect(() => {
    let timeoutId;
    if (step === "loading-screen") {
      timeoutId = setInterval(() => {
        refetchOrder();
      }, 3000);
    }
    return () => {
      if (!!timeoutId) clearInterval(timeoutId);
    };
  }, [step, refetchOrder, activeOrders]);

  const minAmount = useMemo(
    () => Math.ceil(MIN_PRICE / priceOneCFA),
    [priceOneCFA, MIN_PRICE]
  );
  const minPrice = useMemo(
    () => minAmount * priceOneCFA,
    [priceOneCFA, minAmount]
  );

  const checkPrice = () => {
    if (price % priceOneCFA > 0) {
      onPriceChange(Math.floor(price / priceOneCFA) * priceOneCFA);
    }

    if (price < minPrice) {
      onPriceChange(minAmount * priceOneCFA);
    }

    if (price > MAX_PRICE) {
      onPriceChange(MAX_PRICE);
      setShowLimit(true);
    }
  };

  if (!releaseForDisplay) return null;

  const enableToBuy = () => price > 0 && amount > 0;

  const purchaseCardClass = classNames({
    "purchase-card": true,
    "purchase-card_h": isHorizontal,
    "purchase-card_my-sells": isMySells,
    "purchase-card_opened": isOpened,
    [`${addClass}`]: addClass,
  });

  let currentAmount = 0; // хранит количество ЦФА в отрендереных релизах

  const buttonNextHandler = () => {
    savePurchaseData({ id, price, amount });

    if (!isLoggedIn) {
      setStep("need-to-enter");
      return;
    }
    if (!isVerified) {
      setStep("need-to-verify");
      return;
    }

    //---Запрос на инициализацию заявки---//
    initOrder.mutate([
      {
        DfaOisKey: releaseForDisplay.idOis,
        Amount: +amount,
      },
    ]);

    // +++
  };

  return (
    <article className={purchaseCardClass}>
      <CloseButton
        addClass="purchase-card__close-btn"
        onClick={() => setIsOpened(false)}
      />
      {step === "calculator" && !isMobile && (
        <>
          <div className="purchase-card__body">
            {!isMySells && (
              <div className="purchase-card__item purchase-card__item_token">
                <span className="purchase-sell-info__key">Цена ЦФА</span>
                <span className="purchase-sell-info__value">
                  {`100 ЦФА = ${releaseForDisplay.price.toLocaleString()} ₽`}
                </span>
              </div>
            )}

            <div className="purchase-card__item">
              <span className="purchase-sell-info__key">О выпуске</span>
              <div className="purchase-card__releases-grid">
                <div key={`purchase-releaseForDisplay-${releaseForDisplay.id}`}>
                  <ReleaseLink release={releaseForDisplay} />

                  <span className="purchase-card__release-info">
                    {formatTokensForDisplay(
                      releaseForDisplay.availableTokens
                    ).toLocaleString()}
                  </span>
                </div>

                {/* {activeReleases.map((releaseForDisplay, i) => {
                  const availableTokens = formatTokensForDisplay(
                    releaseForDisplay.availableTokens
                  );
                  if (i === 0 || amount > currentAmount) {
                    currentAmount += availableTokens;
                    return (
                      <div
                        key={`purchase-releaseForDisplay-${releaseForDisplay.id}`}
                      >
                        <ReleaseLink releaseForDisplay={releaseForDisplay} />
                        {availableTokens > 0 && (
                          <span className="purchase-card__release-info">
                            {availableTokens.toLocaleString()}
                          </span>
                        )}
                      </div>
                    );
                  }
                  return null; // остальные ЦФА отображаться не будут, если количество ЦФА меньше
                })} */}
              </div>
            </div>

            <div className="purchase-card__item purchase-card__item_count">
              <span className="purchase-sell-info__key">Количество ЦФА</span>
              <Input
                type="text"
                step={1}
                min={0}
                labelSize={"small"}
                value={amount}
                onChange={(evt) => onAmountChange(parseInt(evt.target.value))}
                onBlur={checkPrice}
                pattern="\d*"
                addClass={amount > 0 ? null : "text-gray"}
              />
              <span className="purchase-card__descr base-12">
                {`Всего доступно ${totalAmount} ЦФА`}
              </span>
            </div>

            <div className="purchase-card__item purchase-card__item_count">
              <span className="purchase-sell-info__key">Соответствует</span>
              <Input
                addClass={"input_black"}
                disabled
                type="text"
                labelSize={"small"}
                value={
                  amount &&
                  `${+amount / 100} ${numWord(+amount / 100, [
                    "токенизированному",
                    "токенизированным",
                    "токенизированным",
                  ])} м²`
                }
                onChange={(evt) => {
                  evt.preventDefault();
                }}
              />
            </div>

            <div className="purchase-card__item purchase-card__item_price">
              <span className="purchase-sell-info__key">Стоимость</span>
              <Input
                type="text"
                min={minPrice}
                labelSize={"small"}
                value={price}
                pattern="\d*"
                onChange={(evt) => onPriceChange(parseInt(evt.target.value))}
                onBlur={checkPrice}
                addClass={price > 0 ? null : "text-gray"}
              />
              <span className="purchase-card__descr base-12">
                {isMySells ? (
                  `Рекомендованная цена  100 тыс. ₽ за 1 ЦФА`
                ) : (
                  <>
                    Минимальная сумма покупки ЦФА <b>50</b> тыс. ₽
                  </>
                )}
              </span>
            </div>
          </div>

          {showLimit && <LimitInvestor limit={MAX_PRICE} />}

          <Button
            addClass="purchase-card__btn"
            buttonType={enableToBuy() ? "primary" : "secondary"}
            isFull
            disabled={!enableToBuy() || !releaseForDisplay.idOis || isLoading}
            onClick={buttonNextHandler}
            isLoading={isLoading}
          >
            {buttonText}
          </Button>
          <ErrorMessage forRequest={initOrder} isHidden={isLoading} />
        </>
      )}

      {step === "calculator" && isMobile && (
        <div className="success-screen success-screen_inner-padding">
          <div className="success-screen__content">
            <span className="h7 text-blue">
              Покупка недоступна в мобильной версии
            </span>
            <p className="text-gray" style={{ marginTop: 8 }}>
              Пожалуйста используйте компьютер для покупки
            </p>
          </div>
        </div>
      )}

      {step === "sign-form" && (
        <SignForm
          releaseForDisplay={releaseForDisplay}
          // -- Логика для нескольких релизов --
          // releases={activeReleases
          //   .map((releaseForDisplay, i) => {
          //     if (i === 0 || amount > currentAmount) {
          //       currentAmount += releaseForDisplay.availableTokens;
          //       return releaseForDisplay;
          //     }
          //     return null; // остальные релизы отображаться не будут, если количество токенов меньше
          //   })
          //   .filter((it) => it !== null)}
          onProspectusError={() => {
            setStep("prospectus-error");
          }}
          onBackButton={() => {
            setStep("calculator");
          }}
          afterSubmit={() => refetchOrder()}
          amount={amount}
          order={order}
        />
      )}

      {step === "loading-screen" && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 15,
            alignItems: "center",
          }}
        >
          <p style={{ fontSize: 18 }}>Запрос обрабатывается</p>
          <Button
            onClick={refetchOrder}
            isLoading={isActiveOrdersLoading}
            disabled={isActiveOrdersLoading}
          >
            Обновить статус сделки
          </Button>
        </div>
      )}

      {step === "payment-screen" && (
        <PaymentScreen
          orders={activeOrders}
          orderId={order.internalId}
          onSuccessPayment={() => setStep("success-screen")}
        />
      )}

      {step === "success-screen" && activeOrders && activeOrders.length > 0 && (
        <SuccessScreen
          onReset={() => setStep("calculator")}
          showBuyAgainButton={["PAYMENT_CONFIRMED", "WAITING_ISSUE"].includes(
            activeOrders[activeOrders.length - 1].status
          )}
        />
      )}

      {step === "need-to-enter" && (
        <NeedToEnterScreen closeModal={() => setIsOpened(false)} />
      )}

      {step === "need-to-verify" && (
        <NeedToVerifyScreen closeModal={() => setIsOpened(false)} />
      )}

      {step === "prospectus-error" && (
        <ProspectusErrorScreen
          closeModal={() => {
            setStep("calculator");
            setIsOpened(true);
          }}
        />
      )}

      {step === "no-tokens" && (
        <NoTokensScreen closeModal={() => setIsOpened(false)} />
      )}

      {step === "no-api" && (
        <NoApiScreen closeModal={() => setIsOpened(false)} />
      )}
    </article>
  );
};

export default PurchaseCard;
