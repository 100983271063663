import { Link } from "react-router-dom";
import Button from "../../../ui/Button/Button";
import useLoginModal from "../../../../hooks/useLoginModal";
import useIsLoggedIn from "../../../../hooks/useIsLoggedIn";
import { ArrowIcon } from "../TopBar/ArrowIcon";

const BottomNav = ({ withIcons = false, onClick = () => {} }) => {
  const isLoggedIn = useIsLoggedIn();
  const { openModal } = useLoginModal();

  return (
    <ul className="bottombar-nav">
      <li>
        <Link to={"/new"} className="link" onClick={onClick}>
          Новостройки
          {withIcons && (
            <span className="link__icon link__icon_blue">
              <ArrowIcon />
            </span>
          )}
        </Link>
      </li>
      <li>
        {isLoggedIn ? (
          <Link to={"/user/cfa"} className="link" onClick={onClick}>
            Мой метр
            {withIcons && (
              <span className="link__icon link__icon_blue">
                <ArrowIcon />
              </span>
            )}
          </Link>
        ) : (
          <Button isClean className="link" onClick={openModal}>
            Мой метр
            {withIcons && (
              <span className="link__icon link__icon_blue">
                <ArrowIcon />
              </span>
            )}
          </Button>
        )}
      </li>
      <li>
        <Link to={"/work-scheme"} className="link" onClick={onClick}>
          Схема работы
          {withIcons && (
            <span className="link__icon link__icon_blue">
              <ArrowIcon />
            </span>
          )}
        </Link>
      </li>
      <li>
        <Link to={"/faq"} className="link" onClick={onClick}>
          Ответы на вопросы
          {withIcons && (
            <span className="link__icon link__icon_blue">
              <ArrowIcon />
            </span>
          )}
        </Link>
      </li>
    </ul>
  );
};

export default BottomNav;
