export const BuildingStatuses = {
  START_BUILDING: "Старт строительства",
  PERCENT_BUILDING: "Процент готовности",
  COMMISSIONING: "Введение в эксплуатацию",
  FOR_SALE: "Выставление на продажу",
  PAYMENTS: "Произведение выплат",
};

export const RepaymentMethods = {
  GUARANTEED_INCOME: "Фиксированный доход",
  SALES_INCOME: "Распределение доходов от продаж",
};