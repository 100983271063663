import useShowLabelStatus from "../../../hooks/notifications/objectType/initOrder/useShowLabelStatus";
import useShowButton from "../../../hooks/notifications/objectType/initOrder/useShowButton";
import ProgressLabel from "../Label/ProgressLabel";
import Button from "../Button/Button";
import { orderStatusLabelMap } from "../../../admin/components/AdminTable/AdminTableFormaters";

const FinancialTransactions = ({ financialTransactions, isMiniCard, user }) => {
  const { labelStatus } = useShowLabelStatus(financialTransactions, user);
  const { button } = useShowButton(financialTransactions);

  const parseDescription = financialTransactions?.description?.includes(
    "{" || "}",
  )
    ? JSON.parse(financialTransactions?.description)
    : financialTransactions?.description;

  const descriptionTemplate = {
    CANCELED_BY_OIS: (
      <p className="notification-card__desc">
        Ваша <a href="/user/orders">заявка</a> была отклонена ЦФА ХАБ. Возврат
        средств будет осуществлен в течении от одного до трех рабочих дней.Если
        у вас есть вопросы, напишите{" "}
        <a href="mailto:support@1meter.ru">support@1meter.ru</a>
      </p>
    ),
    // Возможно данный шаблон ещё пригодится, пока он будет иметь другой вид
    // CANCELED: (
    //   <p className="notification-card__desc">
    //     Ваша заявка на выпуск{" "}
    //     <a href="/user/cfa">
    //       {parseDescription?.releaseName ? parseDescription?.releaseName : ""}
    //     </a>{" "}
    //     отменена ЦФА ХАБ. Возврат средств будет осуществлен в течении от одного
    //     до трех рабочих дней.Если у вас есть вопросы, напишите{" "}
    //     <a href="mailto:support@1meter.ru">support@1meter.ru</a>
    //   </p>
    // ),
    CANCELED: (
      <p className="notification-card__desc">
        Выпуск{" "}
        <a href="/user/cfa">
          {parseDescription?.releaseName ? parseDescription?.releaseName : ""}
        </a>{" "}
        не состоялся. Возврат средств будет осуществлен в течении от одного до
        трех рабочих дней.Если у вас есть вопросы, напишите{" "}
        <a href="mailto:support@1meter.ru">support@1meter.ru</a>
      </p>
    ),
    EXPIRED: (
      <p className="notification-card__desc">
        Срок вашей заявки на сделку истек.
      </p>
    ),
    FAILED_HANDLE_EVENT_ORDER: (
      <p className="notification-card__desc">
        {financialTransactions?.description}
      </p>
    ),
    WAITING_FOR_PAYMENT: (
      <p className="notification-card__desc">
        Необходимо произвести оплату вашей <a href="/user/orders">заявки.</a>
      </p>
    ),
    INIT_PROSPECTUS_SENT: (
      <p className="notification-card__desc">
        Ваша заявка отправлена в ЦФА ХАБ.
      </p>
    ),
    INIT_PROSPECTUS_CREATED: (
      <p className="notification-card__desc">
        Ваша заявка ожидает отправку в ЦФА ХАБ.
      </p>
    ),
    WAITING_FOR_CONFIRMATION: (
      <p className="notification-card__desc">
        По вашей заявке идет проверка оплаты. Это займет от одного до трех
        рабочих дней. Статус сделки отображается в личном кабинете.
      </p>
    ),
    WAITING_ISSUE: (
      <p className="notification-card__desc">
        <a href="/user/orders">Ваша заявка</a> зарегистрирована в ЦФА ХАБ.
        Ожидается выпуск.
      </p>
    ),
    PAYMENT_CONFIRMED: (
      <p className="notification-card__desc">
        По <a href="/user/orders">вашей завке</a> оплата подтверждена эмитентом.
        Ожидается выпуск.
      </p>
    ),
    CLOSED: (
      <p className="notification-card__desc">
        Выпуск{" "}
        <a href="/user/cfa">
          {parseDescription?.releaseName ? parseDescription?.releaseName : ""}
        </a>{" "}
        состоялся. <a href="/user/orders">Ваша сделка</a> завершена. Вы можете
        распоряжаться Вашими ЦФА. По возникшим вопросам писать на почту -{" "}
        <a href="mailto:support@1meter.ru">support@1meter.ru</a>
      </p>
    ),
    BUY_ORDER_CREATED: (
      <p className="notification-card__desc">
        Производятся операции по покупке ЦФА из выпуска{" "}
        <a href="/user/cfa">
          {parseDescription?.releaseName ? parseDescription?.releaseName : ""}
        </a>{" "}
        в личном кабинете ЦФА ХАБ.
      </p>
    ),
    BUY_ORDER_CLOSED: (
      <p className="notification-card__desc">
        Вы произвели операции по покупке ЦФА из выпуска{" "}
        <a href="/user/cfa">
          {parseDescription?.releaseName ? parseDescription?.releaseName : ""}
        </a>{" "}
        в личном кабинете ЦФА ХАБ, количество ЦФА обновилось.
      </p>
    ),
    SELL_ORDER_CREATED: (
      <p className="notification-card__desc">
        Производятся операции по продаже ЦФА из выпуска{" "}
        <a href="/user/cfa">
          {parseDescription?.releaseName ? parseDescription?.releaseName : ""}
        </a>{" "}
        в личном кабинете ЦФА ХАБ.
      </p>
    ),
    SELL_ORDER_CLOSED: (
      <p className="notification-card__desc">
        Вы произвели операции по продаже ЦФА из выпуска{" "}
        <a href="/user/cfa">
          {parseDescription?.releaseName ? parseDescription?.releaseName : ""}
        </a>{" "}
        в личном кабинете ЦФА ХАБ, количество ЦФА обновилось.
      </p>
    ),
    BURNING_CREATED: (
      <p className="notification-card__desc">
        Начата процедура погашения ЦФА в выпуске{" "}
        <a href="/user/cfa">
          {parseDescription?.releaseName ? parseDescription?.releaseName : ""}
        </a>
        . Ожидайте квитанцию от эмитента. По возникшим вопросам писать на почту
        - <a href="mailto:support@1meter.ru">support@1meter.ru</a>
      </p>
    ),
    BURNING_CLOSED: (
      <p className="notification-card__desc">
        Вы подтвердили погашения ЦФА в выпуске{" "}
        <a href="/user/cfa">
          {parseDescription?.releaseName ? parseDescription?.releaseName : ""}
        </a>
        . По возникшим вопросам писать на почту -{" "}
        <a href="mailto:support@1meter.ru">support@1meter.ru</a>
      </p>
    ),
    BURNING_WAITING_CONFIRMATION: (
      <p className="notification-card__desc">
        Эмитент направил документ об оплате по выпуску{" "}
        <a href="/user/cfa">
          {parseDescription?.releaseName ? parseDescription?.releaseName : ""}
        </a>
        . Если Вы получили выплату за погашение ЦФА, пожалуйста,{" "}
        <a href="/user/cfa"> подтвердите оплату</a>
      </p>
    ),
    BURNING_WAITING_PAYMENT: (
      <p className="notification-card__desc">
        Вы отклонили квитанцию по погашению в выпуске{" "}
        <a href="/user/cfa">
          {parseDescription?.releaseName ? parseDescription?.releaseName : ""}
        </a>
        . Ожидайте новую.
      </p>
    ),
    BURNING_CONFIRMATION_SENT: (
      <p className="notification-card__desc">
        Ваше подтверждение на погашение ЦФА в выпуске{" "}
        <a href="/user/cfa">
          {parseDescription?.releaseName ? parseDescription?.releaseName : ""}
        </a>{" "}
        отправлено.
      </p>
    ),
    BURNING_CONFIRMED: (
      <p className="notification-card__desc">
        Процедура подтверждения погашения ЦФА в выпуске{" "}
        <a href="/user/cfa">
          {parseDescription?.releaseName ? parseDescription?.releaseName : ""}
        </a>
        .
      </p>
    ),
    BURNING_REJECT_SENT: (
      <p className="notification-card__desc">
        Процедура отклонения по погашению ЦФА в выпуске{" "}
        <a href="/user/cfa">
          {parseDescription?.releaseName ? parseDescription?.releaseName : ""}
        </a>
        .
      </p>
    ),
    TRANSFER_CREATED: (
      <p className="notification-card__desc">
        Производятся операции по передачи ЦФА из выпуска{" "}
        <a href="/user/cfa">
          {parseDescription?.releaseName ? parseDescription?.releaseName : ""}
        </a>{" "}
        в личном кабинете ЦФА ХАБ.
      </p>
    ),
    TRANSFER_CLOSED: (
      <p className="notification-card__desc">
        Произведены операции по передачи ЦФА из выпуска{" "}
        <a href="/user/cfa">
          {parseDescription?.releaseName ? parseDescription?.releaseName : ""}
        </a>{" "}
        в личном кабинете ЦФА ХАБ.
      </p>
    ),
    EXCHANGE_CREATED: (
      <p className="notification-card__desc">
        Производятся операции по обмену ЦФА из выпуска{" "}
        <a href="/user/cfa">
          {parseDescription?.releaseName ? parseDescription?.releaseName : ""}
        </a>{" "}
        в личном кабинете ЦФА ХАБ.
      </p>
    ),
    EXCHANGE_CLOSED: (
      <p className="notification-card__desc">
        Произведены операции по обмену ЦФА из выпуска{" "}
        <a href="/user/cfa">
          {parseDescription?.releaseName ? parseDescription?.releaseName : ""}
        </a>{" "}
        в личном кабинете ЦФА ХАБ.
      </p>
    ),
  };

  return (
    <>
      {labelStatus && (
        <ProgressLabel status={labelStatus}>
          {orderStatusLabelMap[labelStatus]}
        </ProgressLabel>
      )}

      {descriptionTemplate[labelStatus] ?? (
        <p className="notification-card__desc">
          {parseDescription?.status
            ? parseDescription?.status
            : financialTransactions?.description}
        </p>
      )}

      {button.nameBtn && (
        <Button isLink={button.linkBtn} size={isMiniCard ? "xs" : "s"}>
          {button.nameBtn}
        </Button>
      )}
    </>
  );
};
export default FinancialTransactions;
