import "./index.scss";
import OneIcon from "./icons/1.svg";
import TwoIcon from "./icons/2.svg";
import ThreeIcon from "./icons/3.svg";
import FourIcon from "./icons/4.svg";
import Example1 from "./icons/example1.svg";
import Example2 from "./icons/example2.svg";
import Example3 from "./icons/example3.svg";
import Example4 from "./icons/example4.svg";

import Button from "../../components/ui/Button/Button";
import Logo from "../../components/ui/Logo/Logo";

const WorkSchemePage = () => {
  return (
    <section className="scheme">
      <h1 className="title">Схема работы платформы</h1>

      <div className="scheme-block">
        <div className="scheme-block__inner">
          <div className="scheme-block__item">
            <img src={OneIcon} alt="" />
            <p>Выделяются конкретные квартиры</p>
          </div>
          <div className="scheme-block__item">
            <img src={TwoIcon} alt="" />
            <p>Квартиры выводятся с продаж</p>
          </div>
          <div className="scheme-block__item">
            <img src={ThreeIcon} alt="" />
            <p>
              Фиксируется стоимость м<sup>2</sup> до ввода в эксплуатацию и
              после ввода
            </p>
          </div>
          <div className="scheme-block__item">
            <img src={FourIcon} alt="" />
            <p>Устанавливается срок погашения</p>
          </div>
        </div>
      </div>

      <div className="scheme-road">
        <div className="scheme-road__item">
          <Button isLabel>Застройщик</Button>
        </div>
        <div className="scheme-road__item">
          <Button isLabel buttonType="secondary">
            Инвестиционный договор
          </Button>
        </div>

        <div className="scheme-road__item-double">
          <Button isLabel buttonType="secondary">
            Эмитент
          </Button>
          <Button isLabel buttonType="secondary">
            Входит в ГК застройщика
          </Button>
        </div>
        <div className="scheme-road__item">
          <Button isLabel buttonType="secondary">
            Решение о выпуске
          </Button>
        </div>
        <div className="scheme-road__item">
          <Button isLabel>ЦФА ХАБ</Button>
        </div>
        <div className="scheme-road__item">
          <Button isLabel buttonType="secondary">
            Размещение
          </Button>
        </div>

        <Logo />
      </div>
      <div className="scheme__example">
        <h2 className="title">Пример работы</h2>

        <div className="scheme__example-road">
          <div className="scheme__example-item">
            <img src={Example1} alt="" />
            <div className="scheme__example-block">
              В инвестиционном договоре учувствует квартира 30 м<sup>2</sup>
            </div>
          </div>
          <div className="scheme__example-item">
            <img src={Example2} alt="" />
            <div className="scheme__example-block">
              До ввода в эксплуатацию 1 м<sup>2</sup> = 100 000 ₽ = 100 ЦФА* * 1 ЦФА = 1000
              руб
            </div>
          </div>
          <div className="scheme__example-item">
            <img src={Example3} alt="" />
            <div className="scheme__example-block">
              Объем выпуска 3 000 000 ₽ Количество ЦФА 3 000
            </div>
          </div>
          <div className="scheme__example-item">
            <img src={Example4} alt="" />
            <div className="scheme__example-block">
              После ввода в эксплуатацию 1 м<sup>2</sup> = 200 000 ₽ = 100 ЦФА*
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WorkSchemePage;
