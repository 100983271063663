import Button from "../ui/Button/Button";
import Checkbox from "../ui/Checkbox/Checkbox";
import CloseButton from "../ui/CloseButton/CloseButton";
import { useState } from "react";
import "./index.scss";
const ExchangeModal = ({ closeModal }) => {
  const [checkboxValue, setCheckboxValue] = useState(false);

  const [requestStatus, setRequestStatus] = useState(false);

  return (
    <div className="modal">
      <CloseButton addClass="modal__close-btn" onClick={closeModal} />
      <span className="modal__title">ЖК «Аист»</span>

      <div className="sell-info">
        <div className="sell-info__col">
          <span className="sell-info__title">Продам</span>
          <span className="sell-info__key">ЖК «Аист»</span>
          <p className="sell-info__value">СК «Красная стрела»</p>

          <span className="sell-info__key">Санкт-Петербург</span>
          <p className="sell-info__value">Красносельский район</p>

          <span className="sell-info__key">100 ЦФА = 185 273 ₽</span>
          <p className="sell-info__value">Цена 100 ЦФА в выпуске до ввода в эксплуатацию</p>
        </div>

        <div className="sell-info__col">
          <span className="sell-info__title">Количество</span>
          <span className="sell-info__key">2 ЦФА</span>
          <p className="sell-info__value">Цифрового метра</p>
        </div>

        <div className="sell-info__col">
          <span className="sell-info__title">Стоимость</span>
          <span className="sell-info__key">300 000 ₽</span>
          <p className="sell-info__value">От пользователя</p>

          <span className="sell-info__key">150 000 ₽</span>
          <p className="sell-info__value">Стоимость за 1 м²</p>
        </div>
      </div>

      <div className="modal__info text-block">
        {!requestStatus ? (
          <>
            <p>
              При нажатии кнопки «Купить» у продавца будет 2 часа, чтобы
              подтвердить сделку. После подтверждения у вас будет 2 часа на
              оплату.
            </p>

            <p>
              Если запрос отправлен в ночное время, то ожидайте подтверждения до
              13:00.
            </p>
          </>
        ) : (
          <p>Запрос отправлен пользователю ожидайте подтверждения.</p>
        )}
      </div>

      <Checkbox
        withLabel="Я ознакомился с условиями сделки"
        checkboxValue={checkboxValue}
        setCheckboxValue={setCheckboxValue}
      />

      {!requestStatus ? (
        <Button
          addClass="modal__btn"
          isFull
          disabled={!checkboxValue}
          onClick={() => setRequestStatus(true)}
        >
          Купить
        </Button>
      ) : (
        <Button addClass="modal__btn" isFull disabled>
          Запрос отправлен
        </Button>
      )}
    </div>
  );
};

export default ExchangeModal;
