import CloseButton from "../../ui/CloseButton/CloseButton";
import StepLogin from "./StepLogin";
import { useState } from "react";
import "../index.scss";
import classNames from "classnames";

import { useForm, FormProvider } from "react-hook-form";

import { useMutation } from "react-query";
import { registration } from "../../../api";
import BackBtn from "../../ui/BackBtn/BackBtn";
import useLogin from "../../../hooks/useLogin";
import RegisterForm from "../../forms/RegisterForm/RegisterForm";
import * as yup from "yup";
import { formatDate } from "../../../utils";

const schema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  middleName: yup.string(),
  birthDay: yup.string("Введите дату в формате YY-MM-DD").required(),
  email: yup.string().email("Неверный формат почты").required(),
  phone: yup.string().required(),
  password: yup.string().required(),
  receiveNotificationsByEmail: yup.boolean(),
  newTokenNotification: yup.boolean(),
  changePriceNotification: yup.boolean(),
  changeBuildingStatusNotification: yup.boolean(),
  confirmationCode: yup.string().required(),
  type: yup.string().oneOf(["PHONE"]),
});

const LoginModal = ({
  closeModal,
  changePhoneRequest = false,
  isLoginAction = false,
  openOisModal,
}) => {
  const [codeFilled, setCodeFilled] = useState(false);
  const regFormMethods = useForm();
  const [errorMessage, setErrorMessage] = useState("");

  // const isLoggedIn = useUserStore((state) => state.isLoggedIn);
  // const setUser = useUserStore((state) => state.setUser);
  // const { data } = useQuery({
  //   queryKey: ["user"],
  //   queryFn: () => {
  //     getUser().then((user) => {
  //       setUser(user);
  //     });
  //   },
  //   enabled: isLoggedIn,
  // });

  const onClickBackCodeFilled = () => {
    setCodeFilled(false);
    setErrorMessage("");
  };

  const modalClass = classNames({
    modal: true,
    modal_lg: codeFilled,
  });

  const regMutation = useMutation({
    mutationFn: (body) => {
      return registration(body);
    },
    // onSuccess: (data) => {
    //   queryClient.invalidateQueries({
    //     queryKey: ["events"],
    //   });
    // },
  });

  const login = useLogin();

  const onSubmit = (data) => {
    regMutation.mutate(
      {
        ...data,
        userType: "UL", // TODO - временно
        phone: data.phone.replace(/[+\s-]/g, ""),
        birthDay: formatDate(data.birthDay),
        password: "123",
        receiveNotificationsByEmail: true,
        newTokenNotification: true,
        changePriceNotification: true,
        changeBuildingStatusNotification: true,
        confirmationCode: localStorage.getItem("code"),
        type: "PHONE",
      },
      {
        onSuccess: (resp) => {
          login.mutate(
            {
              login: resp.phone,
              code: localStorage.getItem("code"),
              type: "PHONE_CODE",
            },
            {
              onSuccess: () => {
                closeModal();
                openOisModal();
                setErrorMessage("");
              },
            }
          );
        },
        onError: (res) => {
          if (res?.response?.data[0]?.fieldName === "add.request.email") {
            setErrorMessage("Некорректный формат адреса электронной почты");
          } else if (
            res?.response?.data[0]?.fieldName === "add.request.phone"
          ) {
            setErrorMessage(
              "Некорректный формат номера телефона.Пример: 77777777777"
            );
          } else {
            setErrorMessage(
              res?.response?.data[0]?.message ||
                res?.response?.data ||
                "Ошибка сервера"
            );
          }
        },
      }
    );
  };
  return (
    <div className={modalClass}>
      <CloseButton addClass="modal__close-btn" onClick={closeModal} />
      <span className="modal__title">
        {!changePhoneRequest ? "Личный кабинет" : "Смена номера"}
      </span>
      <FormProvider {...regFormMethods}>
        <form onSubmit={regFormMethods.handleSubmit(onSubmit)}>
          {!codeFilled ? (
            <StepLogin
              codeFilled={codeFilled}
              setCodeFilled={setCodeFilled}
              changePhoneRequest={changePhoneRequest}
              closeModal={closeModal}
              isLoginAction={isLoginAction}
            />
          ) : (
            <>
              <BackBtn
                addClass="modal__back-btn"
                onClick={onClickBackCodeFilled}
              />
              <RegisterForm errorMessage={errorMessage} />
            </>
          )}
        </form>
      </FormProvider>
    </div>
  );
};

export default LoginModal;
