import classNames from "classnames";
import Button from "../../ui/Button/Button";
import { BellSvg, UserSvg } from "./Icons";

import Modal from "react-modal";
import useModal from "../../../hooks/useModal";
import LoginModal from "../../modals/LoginModal/LoginModal";

import "./UserMenu.scss";
import { Link } from "react-router-dom";
import useIsLoggedIn from "../../../hooks/useIsLoggedIn";
import DropdownMenu from "./DropdownMenu/DropdownMenu";
import WalletButton from "../../WalletButton/WalletButton";
import { config } from "../../../config";
import OisModal from "../../modals/OisModal";
import useLoginModal from "../../../hooks/useLoginModal";
import useUser from "../../../hooks/useUser";
import { getFullName } from "../../../utils";
import DropdownMenuNotifications from "./DropdownMenuNotifications/DropdownMenuNotifications";
import useClickOutside from "../../../hooks/useClickOutside";
import { useCountNewNotifications } from "../../../store";
import useMobile from "../../../hooks/useMobile";

const UserMenu = () => {
  const isMobile = useMobile();
  const isLoggedIn = useIsLoggedIn();
  const { user } = useUser();

  const {
    modalIsOpen,
    openModal,
    closeModal,
    isLoginAction,
    setIsLoginAction,
  } = useLoginModal();

  const countNewNotifications = useCountNewNotifications(
    (state) => state.countNewNotifications
  );
  const setCountNewNotifications = useCountNewNotifications(
    (state) => state.setCountNewNotifications
  );
  const {
    modalIsOpen: oisModalIsOpen,
    openModal: openOisModal,
    closeModal: closeOisModal,
  } = useModal();

  const userMenuClass = classNames({
    "user-menu": true,
  });

  const { ref, isOpened, setIsOpened } = useClickOutside();
  let delay = null;

  function openNotificationsModal() {
    setCountNewNotifications(null);
    setIsOpened(true);
  }

  const handleMouseEnterOpenNotifications = () => {
    clearTimeout(delay);
    delay = setTimeout(openNotificationsModal, 500);
  };

  const handleMouseLeaveBell = () => {
    clearTimeout(delay);
  };

  const handleMouseLeaveCloseNotifications = () => {
    setIsOpened(false);
  };

  const handleClickCloseNotifications = () => {
    clearTimeout(delay);
  };

  return (
    <div className={userMenuClass}>
      {isLoggedIn ? (
        <div className="user-menu__interface">
          {config.dev_mode && (
            <>
              <WalletButton balance={"370 000"} />
            </>
          )}

          {user && (
            <Link
              to="/settings/personal"
              style={{ display: "flex", alignItems: "center" }}
            >
              {!isMobile && (
                <p
                  style={{
                    marginRight: 15,
                    color: "#3399CC",
                    fontWeight: "bold",
                  }}
                >
                  {getFullName(user)}
                </p>
              )}

              <div className="user-menu__profile">
                <UserSvg />
              </div>
            </Link>
          )}
          <div
            onMouseLeave={!isMobile ? handleMouseLeaveBell : () => {}}
            onMouseEnter={
              !isMobile ? handleMouseEnterOpenNotifications : () => {}
            }
            onClick={!isMobile ? handleClickCloseNotifications : () => {}}
            className="user-menu__notification-container"
          >
            <Link
              to="/settings/notifications"
              className="user-menu__notification"
            >
              <BellSvg />
              {countNewNotifications && (
                <span className="user-menu__notification-counter">
                  {countNewNotifications}
                </span>
              )}
            </Link>
            <DropdownMenuNotifications
              isOpened={isOpened}
              notificationsRef={ref}
              handleMouseLeaveCloseNotifications={
                handleMouseLeaveCloseNotifications
              }
              countNewNotifications={countNewNotifications}
              setCountNewNotifications={setCountNewNotifications}
            />
          </div>
          <DropdownMenu />
        </div>
      ) : (
        <div className="user-menu__buttons">
          <Button
            buttonType="secondary"
            size={"m"}
            onClick={() => {
              setIsLoginAction(false);
              openModal();
            }}
          >
            Регистрация
          </Button>
          <Button
            buttonType="primary"
            size={"m"}
            onClick={() => {
              setIsLoginAction(true);
              openModal();
            }}
          >
            Вход
          </Button>
        </div>
      )}

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="modal-wrapper"
        overlayClassName="modal-overlay"
      >
        <LoginModal
          closeModal={closeModal}
          isLoginAction={isLoginAction}
          openOisModal={openOisModal}
        />
      </Modal>

      <Modal
        isOpen={oisModalIsOpen}
        onRequestClose={closeOisModal}
        className="modal-wrapper"
        overlayClassName="modal-overlay"
      >
        <OisModal closeModal={closeOisModal} />
      </Modal>
    </div>
  );
};

export default UserMenu;
