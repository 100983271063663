import classNames from "classnames";
import Button from "../../../ui/Button/Button";

import CloseButton from "../../../ui/CloseButton/CloseButton";
import { useState, useMemo, useEffect } from "react";

import NeedToEnterScreen from "../../../ui/PurchaseCard/NeedToEnterScreen";
import NeedToVerifyScreen from "../../../ui/PurchaseCard/NeedToVerifyScreen";
import NoTokensScreen from "../../../ui/PurchaseCard/NoTokensScreen";
import NoApiScreen from "../../../ui/PurchaseCard/NoApiScreen";
import ProspectusErrorScreen from "../../../ui/PurchaseCard/ProspecusErrorScreen";
import { createSignatureCrypto, downloadFile } from "../../../../utils";
import usePathId from "../../../../hooks/usePathId";
import useIsLoggedIn from "../../../../hooks/useIsLoggedIn";
import useIsVerified from "../../../../hooks/useIsVerified";

import useOisHealth from "../../../../hooks/fetchers/useOisHealth";

import useEscButton from "../../../../hooks/useEscButton";
import useUserId from "../../../../hooks/useUserId";
import useMobile from "../../../../hooks/useMobile";

import SignForm from "../../../ui/PurchaseCard/SignForm";
import ReleaseLink from "../../../ui/ReleaseLink/ReleaseLink";
import {
  getOrders,
  rejectPaymentInvestorMutation,
  sendPaymentInvestorConfirmMutation,
} from "../../../../api";
import { useMutation, useQuery, useQueryClient } from "react-query";

import useUserCryptoProfile from "../../../../hooks/fetchers/user/useUserCryptoProfile";
import {
  useDocumentById,
  useProspectusById,
} from "../../../../hooks/fetchers/orders";
import { getUserCertificates } from "crypto-pro";
import Textarea from "../../../ui/Textarea/Textarea";
import { Loader } from "../../../ui/Loader/Loader";

const ConfirmationCard = ({
  releaseForDisplay,
  addClass,
  isOpened,
  setIsOpened,
}) => {
  const isMobile = useMobile();
  useEscButton(() => setIsOpened(false));
  const isLoggedIn = useIsLoggedIn();
  const isVerified = useIsVerified();

  const [isRejectConfirmActive, setRejectConfirmActive] = useState(false);
  const [step, setStep] = useState("confirmation");

  const { data: oisHealth } = useOisHealth({
    onSuccess: () => {
      if (!oisHealth) setStep("no-api");
    },
  });

  const { data: ordersInRelease } = useQuery({
    queryKey: ["orders", releaseForDisplay?.id],
    enabled: !!releaseForDisplay,
    queryFn: () =>
      getOrders({
        releaseId: releaseForDisplay.idOis,
        orderType: "BURNING",
      }),
  });

  // const order = useMemo(
  //   () =>
  //     ordersInRelease.find((order) => order.status === "WAITING_CONFIRMATION"),
  //   [ordersInRelease]
  // );

  // TODO Переделать ордер
  const order = useMemo(
    () => ordersInRelease && ordersInRelease[0],
    [ordersInRelease]
  );

  const purchaseCardClass = classNames({
    "purchase-card purchase-card_h purchase-card_confirmation": true,
    "purchase-card_opened": isOpened,
    "purchase-card_add-comment": isRejectConfirmActive,
    [`${addClass}`]: addClass,
  });

  const buttonNextHandler = () => {
    if (!isLoggedIn) {
      setStep("need-to-enter");
      return;
    }
    if (!isVerified) {
      setStep("need-to-verify");
      return;
    }

    setStep("sign-form");
  };
  const queryClient = useQueryClient();
  const [isConfirming, setIsConfirming] = useState(false);
  const [signFile, setSignFile] = useState("");
  const [hashError, setHashError] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [isActiveLoader, setActiveLoader] = useState(false);
  const [commentRejectConfirmation, setCommentRejectConfirmation] =
    useState("");

  const { data: cryptoProfile, isLoading: isCryptoProfileLoading } =
    useUserCryptoProfile({
      enabled: isLoggedIn && isConfirming,
    });

  // получаем проспект файл
  const {
    data: prospectusFile,
    isSuccess: isProspectusSuccess,
    isLoading: isProspectusLoading,
    isError: isProspectusError,
  } = useProspectusById(
    {
      prospectusId: order?.prospectusId,
    },
    {
      enabled: !!order,
    }
  );

  const onConfirmHandler = () => {
    setIsConfirming(true);
    setRejectConfirmActive(false);
  };

  // закомментировал, не удалось понять для чего нужен данный запрос
  // const { data: prospectusFile, isLoading: isProspectusLoading } =
  //   useDocumentById(
  //     {
  //       id: order?.prospectusId,
  //       enabled: isConfirming,
  //     },
  //     {},
  //   );

  const getCertificationsCryptoPro = async () => {
    try {
      await getUserCertificates().then((certs) => {
        let findCurrentCert = "";
        certs.forEach((certificate) => {
          cryptoProfile?.forEach((el) => {
            if (el.Thumbprint === certificate.thumbprint) {
              findCurrentCert = certificate.thumbprint;
            }
          });
        });
        createSignatureCrypto(
          prospectusFile,
          setErrorMessage,
          setHashError,
          setSignFile,
          findCurrentCert
        );
      });
    } catch (error) {
      setErrorMessage(`${error.message} попробуйте перезагрузать страницу`);
    }
    setIsConfirming(false);
  };

  useEffect(() => {
    if (prospectusFile && cryptoProfile && isConfirming) {
      getCertificationsCryptoPro();
    }
  }, [prospectusFile, cryptoProfile, isConfirming]);

  useEffect(() => {
    if (signFile) {
      onSendSignPayment();
    }
  }, [signFile]);

  const startRefetchInterval = () => {
    const intervalId = setInterval(() => {
      queryClient.invalidateQueries({
        queryKey: ["orders"],
      });
    }, 1000);
    setTimeout(() => {
      clearInterval(intervalId);
      setActiveLoader(false);
      setIsOpened(false);
    }, 20000);
  };

  // Отправка файла подписи
  const {
    mutate: onSendSignPayment,
    isSuccess: onSendSignPaymentSuccess,
    isLoading: onSendSignPaymentLoading,
  } = useMutation({
    mutationFn: () => {
      const file = new File(
        [signFile],
        `prospectus${order.prospectusId}.json.p7s`,
        {
          encoding: "UTF-8",
        }
      );

      const formularData = new FormData();
      formularData.append("sign", file);

      return sendPaymentInvestorConfirmMutation({
        id: order.internalId,
        file: formularData,
      });
    },
    onSuccess: (data) => {
      setActiveLoader(true);
      setRejectConfirmActive(false);
      startRefetchInterval();
    },
    onError: (data) => {
      setErrorMessage(data.message);
    },
  });

  // Отклонение подтверждения
  const {
    mutate: onRejectPayment,
    isSuccess: onRejectPaymentSuccess,
    isLoading: onRejectPaymentLoading,
  } = useMutation({
    mutationFn: () => {
      return rejectPaymentInvestorMutation({
        id: order.internalId,
        comment: commentRejectConfirmation,
      });
    },
    onSuccess: (data) => {
      setRejectConfirmActive(false);
      setActiveLoader(true);
      startRefetchInterval();
    },
    onError: (data) => {
      setErrorMessage(data.message);
    },
  });

  const DownloadTicketButton = ({ id, isTicketAvailable }) => {
    const { refetch, isLoading } = useDocumentById(
      {
        id,
        enabled: false,
      },
      {
        onSuccess: (file) => {
          downloadFile(file, "document.pdf");
        },
      }
    );

    return (
      <>
        <Button
          buttonType={isTicketAvailable ? "primary" : "secondary"}
          disabled={!isTicketAvailable || isLoading}
          isLoading={isLoading}
          size="s"
          style={{ marginTop: 10 }}
          onClick={() => {
            setErrorMessage(null);
            refetch().then((resp) => {
              if (resp.status === "error") {
                setErrorMessage("Ошибка: Временный файл не найден");
              }
              if (resp && resp.data) {
                downloadFile(new Blob([resp.data]), "payment-document.pdf");
              }
            });
          }}
        >
          {isTicketAvailable ? "Скачать квитанцию" : "Ожидание квитанции"}
        </Button>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
      </>
    );
  };

  return (
    <article className={purchaseCardClass}>
      <CloseButton
        addClass="purchase-card__close-btn"
        onClick={() => setIsOpened(false)}
      />
      {step === "confirmation" && !isMobile && (
        <>
          {releaseForDisplay && (
            <div className="purchase-card__body">
              <div className="confirm-info">
                <ReleaseLink release={releaseForDisplay} />
                <p className="confirm-info__text">
                  Подтвердите выплату ЦФА. Проверьте реквизиты и сумму
                </p>
                <p className="confirm-info__text">
                  <span className="confirm-info__text-bold">Сумма сделки:</span>{" "}
                  {order.price} рублей
                </p>
                <p className="confirm-info__text">
                  <span className="confirm-info__text-bold">
                    Количество ЦФА:
                  </span>{" "}
                  {order.amount}
                </p>
                <p className="confirm-info__text">
                  Платежный документ:{" "}
                  <DownloadTicketButton
                    id={order.paymentDocumentId}
                    isTicketAvailable={
                      order.status !== "WAITING_FOR_PAYMENT" &&
                      order.paymentDocumentId !== null
                    }
                  />
                </p>
                {isActiveLoader ? (
                  <Loader isBlue isLarge />
                ) : (
                  <div className="flex gap-10" style={{ marginTop: 25 }}>
                    <Button
                      buttonType="primary"
                      size="s"
                      onClick={onConfirmHandler}
                      isLoading={onSendSignPaymentLoading}
                    >
                      Подтвердить
                    </Button>
                    <Button
                      buttonType="secondary-red"
                      size="s"
                      onClick={() =>
                        setRejectConfirmActive(!isRejectConfirmActive)
                      }
                    >
                      Отклонить
                    </Button>
                  </div>
                )}
                {errorMessage && (
                  <p className="error-message">{errorMessage}</p>
                )}
                {isRejectConfirmActive && (
                  <div
                    style={{
                      marginTop: "25px",
                      display: "flex",
                      flexDirection: "column",
                      gap: 10,
                    }}
                  >
                    <Textarea
                      value={commentRejectConfirmation}
                      setValue={setCommentRejectConfirmation}
                      withLabel="Комментарий(необязательно)"
                      addClass="form__input"
                      size="lg"
                      labelSize="small"
                      placeholder={""}
                    />
                    <Button
                      buttonType="primary"
                      size="s"
                      onClick={onRejectPayment}
                      isLoading={onRejectPaymentLoading}
                    >
                      Отправить
                    </Button>
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      )}

      {step === "confirmation" && isMobile && (
        <div className="success-screen success-screen_inner-padding">
          <div className="success-screen__content">
            <span className="h7 text-blue">
              Покупка недоступна в мобильной версии
            </span>
            <p className="text-gray" style={{ marginTop: 8 }}>
              Пожалуйста используйте компьютер для покупки
            </p>
          </div>
        </div>
      )}

      {step === "sign-form" && (
        <SignForm
          isBurning
          releaseForDisplay={releaseForDisplay}
          onProspectusError={() => {
            setStep("prospectus-error");
          }}
          onBackButton={() => {
            setStep("confirmation");
          }}
          afterSubmit={() => {}}
          order={order}
        />
      )}
      {step === "loading-screen" && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 15,
            alignItems: "center",
          }}
        >
          <p style={{ fontSize: 18 }}>Запрос обрабатывается</p>
          <Button>Обновить статус сделки</Button>
        </div>
      )}

      {step === "payment-screen" && <>Payment Screen</>}

      {step === "success-screen" && <>Success Screen</>}

      {step === "need-to-enter" && (
        <NeedToEnterScreen closeModal={() => setIsOpened(false)} />
      )}

      {step === "need-to-verify" && (
        <NeedToVerifyScreen closeModal={() => setIsOpened(false)} />
      )}

      {step === "prospectus-error" && (
        <ProspectusErrorScreen
          closeModal={() => {
            setStep("confirmation");
            setIsOpened(true);
          }}
        />
      )}

      {step === "no-tokens" && (
        <NoTokensScreen closeModal={() => setIsOpened(false)} />
      )}

      {step === "no-api" && (
        <NoApiScreen closeModal={() => setIsOpened(false)} />
      )}
    </article>
  );
};

export default ConfirmationCard;
