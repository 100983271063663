import classNames from "classnames";
import "./index.scss";
import Popup from "../Popup/Popup";
import ComparisonImg from "../../../assets/img/comparison.png";

const ComparisonBlock = ({ data, repaymentMethod = "" }) => {
  const comparisonClass = classNames({
    "comparison-block": true,
  });

  if (!data) return null;

  return (
    <article className={comparisonClass}>
      <div className="comparison-block__content">
        <div className="comparison-block__box">
          <div className="comparison-block__title">
            Цена 100 ЦФА до ввода в эксплуатацию
            <Popup>Стоимость 100 ЦФА в действующем выпуске</Popup>
          </div>

          <p className="comparison-block__price">
            100 ЦФА = {data.price.toLocaleString()} ₽
          </p>
          <span className="comparison-block__text">
            100 ЦФА = стоимости 1 м²
          </span>
        </div>

        <div className="comparison-block__box">
          <div className="comparison-block__title">
            Цена 100 ЦФА после ввода в эксплуатацию
            <Popup>
              {repaymentMethod === "GUARANTEED_INCOME" &&
                "В данном выпуске ЦФА, после завершения строительства, Застройщик выплатит фиксированную гарантированную доходность."}
              {repaymentMethod === "SALES_INCOME" &&
                "В данном выпуске ЦФА, после завершения строительства, Застройщик продаст токенизированные квартиры и распределит доходность между инвесторами."}
            </Popup>
          </div>

          <p className="comparison-block__price">
            100 ЦФА = {data.pool.exitPrice.toLocaleString()} ₽
          </p>
          <span className="comparison-block__text">
            100 ЦФА = стоимости 1 м²
          </span>
        </div>
      </div>
      <img className="comparison-block__pic" src={ComparisonImg} alt="" />
    </article>
  );
};

export default ComparisonBlock;
