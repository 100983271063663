import useMyTokens from "../../../../hooks/fetchers/useMyTokens";
import { Loader } from "../../../ui/Loader/Loader";
import CardHorizontalToken from "./CardHorizontalToken";
import "./index.scss";
import { Fragment } from "react";
// import { useMemo } from "react";

const MyTokens = () => {
  const { data: tokens, isLoading } = useMyTokens();
  // TODO - Переделать на параметр с бэка в запросе, когда он появится
  // const tokens = useMemo(() => {
  //   if (data) {
  //     return [...data].reverse();
  //   }
  //   return null;
  // }, [data]);

  if (isLoading) return <Loader isBlue isLarge />;
  if (!tokens) return null;

  return (
    <div>
      {tokens.length === 0 ? (
        <p style={{ marginTop: 15 }}>У вас еще нет ЦФА</p>
      ) : (
        <section className="my-tokens">
          {tokens.map((token, i) => {
            return (
              <Fragment key={token?.lastOperationDate}>
                <CardHorizontalToken tokenData={token} />
              </Fragment>
            );
          })}
        </section>
      )}
    </div>
  );
};

export default MyTokens;
